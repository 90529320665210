<template>
  <div>
    <viewcard--c title="Turno" :btnew="btnew" :btback="{}">
      <b-row class="mb-1 d-flex justify-content-end">
        <b-col md="5">
          <b-input-group>
            <b-form-input
              placeholder="Pesquise por Nome..."
              autocomplete="off"
              v-model="search"
              @keyup.enter="filter"
            />
            <b-input-group-append>
              <b-button variant="gradient-info" @click="filter">
                <feather-icon icon="SearchIcon" class="mr-50"/>
                <span>Pesquisar</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <view--c
        :permission="'permission.audiovisual.demands.workshift.view'"
        :busy="isloading"
      >
        <Table
          :fields="fields"
          :list="!isloading && list[currentePage] ? list[currentePage].itens : []"
          @orderBy="getRecordsOrderBy"
          @rowClick="onClickSelected"
          border="full"
          responsive
        >
          <template #start="data">
            {{data.item.start | timeFilter}}
          </template>
          <template #end="data">
            {{data.item.end | timeFilter}}
          </template>
        </Table>

        <b-pagination
          v-model="currentePage"
          @change="getLoadMore"
          :total-rows="rows"
          :per-page="size"
          v-if="rows > 1"
          first-number
          last-number
          align="center"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </view--c>
    </viewcard--c>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import _workShiftService from "@/services/audiovisual/work-shift-service";
export default {
  components: {
    Table,
  },
  data() {
    return {
      btnew: {
        permission: "permission.audiovisual.demands.workshift.create",
        to: "/audio-visual/demands/work-shift/0",
      },
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "id", label: "ID", orderBy: { type: "back" } },
        { key: "name", label: "Nome", orderBy: { type: "back" } },
        { key: "start", label: "Início", orderBy: { type: "back" } },
        { key: "end", label: "Término", orderBy: { type: "back" } },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: '',
        ascOrDes: true,
        orderByKey: ''
      },
    };
  },
  created() {
    this.getRecords(this.currentePage);
  },
  filters:{
    timeFilter: function(value) {
      if(value){
        let dataTime = value.split('T')
        let time = dataTime[1].split(':')
        return time[0]+':'+time[1]+'hs'
      } else {
        return '--:--hs'
      }
    }
  },
  methods: {
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _workShiftService
        .show(_page, this.orderByParams)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    onClickSelected(record, _) {
      this.$router.push({
        path: `/audio-visual/demands/work-shift/${record.id}`,
      });
    },
  },
};
</script>